import Splide, { Options } from '@splidejs/splide';

export const carousel = () => {
  const productCarousels = document.querySelectorAll('.wp-block-products') as NodeListOf<HTMLElement>;

  if (productCarousels.length) {
    productCarousels.forEach((carousel) => {
      const prev = carousel.querySelector('.splide__arrow--prev') as HTMLButtonElement;
      const next = carousel.querySelector('.splide__arrow--next') as HTMLButtonElement;

      const options: Options = {
        perPage: 4,
        perMove: 1,
        gap: 24,
        type: 'loop',
        arrows: true,
        prev,
        next,
        pagination: false,
        breakpoints: {
          768: {
            perPage: 2,
          },
          540: {
            perPage: 1,
          },
        },
      };

      new Splide(carousel, options).mount();
    });
  }

  const teamCarousels = document.querySelectorAll('.wp-block-gallery.is-style-carousel') as NodeListOf<HTMLElement>;

  if (teamCarousels.length) {
    teamCarousels.forEach((carousel) => {
      carousel.classList.add('splide');

      // add wrapper & track
      const track = document.createElement('div');
      track.classList.add('splide__track');

      const list = document.createElement('div');
      list.classList.add('splide__list');

      const slides = carousel.querySelectorAll('.wp-block-image') as NodeListOf<HTMLElement>;

      slides.forEach((slide) => {
        slide.classList.add('splide__slide');

        list.appendChild(slide);
      });

      track.appendChild(list);
      carousel.appendChild(track);

      const options: Options = {
        // perPage: 4,
        perMove: 1,
        autoWidth: true,
        gap: 24,
        type: 'loop',
        arrows: true,
        arrowPath: `M0 13.127a13.127 13.127 0 1 1 13.128 13.128A13.132 13.132 0 0 1 0 13.127Zm16.8 0-5.255-5.251v10.5Z`,
        pagination: false,
      };

      new Splide(carousel, options).mount();
    });
  }
};

export const mobileMenu = () => {
  const header = document.querySelector('.site-header');
  const toggleButton = document.querySelector('.menu-toggle');

  if (!header || !toggleButton) {
    return;
  }

  const isMobile = window.matchMedia('not all and (min-width: 1024px)');

  toggleButton?.addEventListener('click', () => {
    document.body.classList.toggle('menu-open');
    document.body.classList.remove('search-open');
  });

  const menuItemsWithChildren = header.querySelectorAll('.menu-item-has-children');

  // toggle submenus
  menuItemsWithChildren.forEach((item) => {
    item.querySelector('a')?.addEventListener('click', (e) => {
      if (!isMobile.matches) return;

      e.preventDefault();

      // close other submenus
      menuItemsWithChildren.forEach((item) => {
        if (!item.contains(e.target as HTMLElement)) {
          item.classList.remove('is-active');
        }
      });

      item.classList.toggle('is-active');
    });
  });

  document.addEventListener('click', (e) => {
    if (!isMobile.matches) return;

    const target = e.target as HTMLElement;

    if (!target.closest('.menu-toggle') && !target.closest('.site-navigation')) {
      document.body.classList.remove('menu-open');
    }
  });
};

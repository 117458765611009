import { carousel } from './carousel';
import { desktopMenu } from './desktop-menu';
import { gforms } from './gforms';
import { mobileMenu } from './mobile-menu';
import { search } from './search';

const rdy = (fn: () => void) => {
  if (document.readyState !== 'loading') {
    fn();
    return;
  }
  document.addEventListener('DOMContentLoaded', fn);
};

// on dom content loaded
rdy(() => {
  desktopMenu();
  mobileMenu();
  search();
  carousel();
  gforms();
});

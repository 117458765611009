export const search = () => {
  const searchButton = document.querySelector('.search .button');

  if (!searchButton) return;

  const searchModal = document.querySelector('.search-modal');

  if (!searchModal) return;

  searchButton.addEventListener('click', (e) => {
    e.preventDefault();
    document.body.classList.toggle('search-open');
    document.body.classList.remove('menu-open');
  });

  // close search modal on outside header/searchmodal click
  document.addEventListener('click', (e: any) => {
    if (!e.target.closest('.search-modal') && !e.target.closest('.site-header')) {
      document.body.classList.remove('search-open');
    }
  });
};
